import React, { useState, useEffect } from 'react';
import Axios from 'axios';

export const DisplayCartItems = ({ prod, update, setUpdate }) => {
  const { p_id, quantity } = prod;
  const [product, setProduct] = useState({});
  const [cartQuantity, setCartQuantity] = useState(quantity);

  const addToCart = async (p_id) => {
    try {
      const response = await Axios.put("https://ecommerce-api.debeshp.com/users/addItem", { user_id: window.localStorage.getItem("user_id"), p_id });
      console.log(response.data);
      setCartQuantity(response.data);
      setUpdate(update + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const removeFromCart = async () => {
    try {
      const response = await Axios.put("https://ecommerce-api.debeshp.com/users/removeItem", { user_id: window.localStorage.getItem("user_id"), p_id });
      console.log(response.data);
      setCartQuantity(response.data);
      setUpdate(update + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const getProduct = async () => {
    try {
      const response = await Axios.get(`https://ecommerce-api.debeshp.com/products/${p_id}`);
      setProduct(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (p_id) {
      getProduct();
    }

  }, [p_id]);

  //console.log({product,cartQuantity});
  return (
    <div className='displayCartItems'>
      {Object.keys(product).length &&
        cartQuantity > 0 ? (
        <>
          <img src={product.product_image_url} />
          <div className='description'>
            <p>{product.product_name}</p>
            <p>${product.product_price}</p>
            <div className='addToCartBttn'>
              <button type='button' onClick={() => removeFromCart(p_id)}>-</button>
              <button type='button'>{cartQuantity}</button>
              <button type='button' onClick={() => addToCart(p_id)}>+</button>
            </div>
          </div>
        </>
      ) : <h1>Loading...</h1>
      }
    </div>
  );
};
